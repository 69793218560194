'use client'

import { useState } from 'react'
import { FieldProps, Form, FormHelpers, FormValues } from 'maker-ui/forms'
import { useRouter } from 'next/navigation'
import { z } from 'zod'

import { useAuth } from '@/hooks'
import { UserType } from '@/types'
import styles from './LoginForm.module.scss'
import { cn } from 'maker-ui/utils'

const loginField: FieldProps[] = [
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Email address',
    type: 'email',
    required: true,
    validation: z.string().email('Must be a valid email address'),
  },
]

const otpField: FieldProps[] = [
  {
    name: 'otp',
    label: 'One Time Passcode',
    placeholder: '6 digit code sent to your email',
    type: 'text',
    required: true,
    validation: z.string().length(6, 'Must be 6 digits'),
  },
]

interface LoginFormProps {
  footer?: React.ReactNode
  emailAddress?: string
  userType?: UserType
}

export const LoginForm = ({
  emailAddress,
  footer,
  userType,
}: LoginFormProps) => {
  const router = useRouter()
  const [email, setEmail] = useState(emailAddress || '')
  const [codeSent, setCodeSent] = useState(emailAddress ? 1 : 0)
  const [error, setError] = useState('')
  const { isLoggedIn, checkForUser, login, verifyOtp } = useAuth()

  async function onSubmitEmail(
    values: FormValues,
    { setIsSubmitting }: FormHelpers
  ) {
    // First check if eligible to register
    const check = await checkForUser(values.email)
    const { success } = await login(values.email, check?.userType)

    if (success) {
      setCodeSent(1)
      setEmail(values.email)
      setError('')
    } else {
      setError('Email does not match any user accounts. Please try again.')
    }

    setIsSubmitting(false)
  }

  async function onSubmitOtp(
    values: FormValues,
    { setIsSubmitting }: FormHelpers
  ) {
    // First check if eligible to register
    const { success } = await verifyOtp(email, values.otp)

    if (success) {
      setError('')
      router.push('/account')
    } else {
      setError('Invalid code. Please try again or request a new code.')
    }

    setIsSubmitting(false)
  }

  async function resendOtp() {
    const { success } = await login(email, userType)

    if (success) {
      setCodeSent(codeSent + 1)
      setError('')
    }
  }

  return isLoggedIn && !email ? (
    <div className={styles.logged_in}>Already logged in.</div>
  ) : codeSent > 0 && email ? (
    <Form
      fields={otpField}
      error={!!error}
      settings={{ validateFieldOnBlur: false }}
      onSubmit={onSubmitOtp}>
      <Form.Header className={styles.header}>
        {codeSent > 1
          ? 'Code resent! Please check your email.'
          : 'Please check your email to verify your account.'}
      </Form.Header>
      <Form.Error>{error}</Form.Error>
      <Form.Submit
        className="btn-primary w-100"
        lifecycle={{ submitting: 'Logging In' }}>
        Login
      </Form.Submit>
      <Form.Footer>
        <button
          type="button"
          className={cn(styles.footer, styles.btn_footer)}
          onClick={() => resendOtp()}>
          Resend Code
        </button>
      </Form.Footer>
    </Form>
  ) : (
    <Form
      fields={loginField}
      error={!!error}
      settings={{ validateFieldOnBlur: false }}
      onSubmit={onSubmitEmail}>
      <Form.Error>{error}</Form.Error>
      <Form.Submit
        className="btn-primary w-100"
        lifecycle={{ submitting: 'Submitting...' }}>
        Login
      </Form.Submit>
      <Form.Footer>
        <div className={styles.footer}>
          Need an account? <a href="/register">Register</a>
        </div>
      </Form.Footer>
    </Form>
  )
}
